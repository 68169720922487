
import { Watch, Mixins, InjectReactive, Prop, Component, Vue, Ref } from "vue-property-decorator";
import { presCategoryTypeList, getCategoryList, prescriptionLoadPres, prescriptionSaveOrUpdate, prescriptionDisable } from "@/api/prescriptionDb";
import { cloneDeep } from "lodash";
import CopyString from "@/mixins/copyString";

const curDomain = window.location.origin;

@Component
export default class PrescriptionOne extends Mixins(CopyString) {
    @Ref("validateForm") readonly validateForm!: VForm;
    @Prop() readonly categoryInfo: any;
    @InjectReactive() pageCurrentTab!: string|number;
    @InjectReactive() merchantId!: string|number;

    curReportUrl =
    curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/recuperate/index"
      : "https://www.yyznmd.cn/h5/#/recuperate/index";

    tableData = [];

    dialogVisible = false;
    btnLoading = false;

    editRow = {};

    baseFormData = {
        categoryInfo: {},
        categoryProductInfo: {},
        categoryDeviceId: "",
        categoryProductId: "",
        productNum: undefined,
        productUnit: "",
    }

    // README: 注意formObj、tagListObj 属性为xxx[n(从1开始递增)] 整个组件为动态循坏
    formObj = {
        formData1: {},
        formData2: {},
        formData3: {},
    }

    tagListObj = {
    }

    tllxOptions = []

    cpmcOptions = []

    unitOptions = [
        { text: "个", value: "个" },{ text: "次", value: "次" },
        { text: "组", value: "组" },{ text: "盒", value: "盒" },
        { text: "套", value: "套" },{ text: "包", value: "包" },
    ]

    switchFlag = true;

    plan = "";
    remark = "";

    firstCategoryList = [];

    // 数量清空的时候，将单位也清空掉
    productNumChange($event, type) {
        if (!$event) {
            this.formObj[`formData${type}`].productUnit = "";
        }
    }

    formDisable(type) {
        const { categoryDeviceId, categoryProductId, productNum, productUnit } = this.formObj[`formData${type}`];
        if (type == 3) {
            return !categoryDeviceId || !categoryProductId || (this.tagListObj[`tagList${type}`]?.length >= 6);
        }
        return !categoryDeviceId || !categoryProductId || !productNum || !productUnit || (this.tagListObj[`tagList${type}`]?.length >= 6);
    }

    mounted() {
        // 第一级类型，考虑到后续可能新增，全部使用后端返回的数据
        presCategoryTypeList({ categoryType: 0, parentId: 0 }).then((res) => {
            this.firstCategoryList = res.data;
            // 将几个form 设置在list 中
            this.firstCategoryList.forEach((item, index) => {
                this.formObj[`formData${item.id}`] = {};
                this.tagListObj[`tagList${item.id}`] = [];
            })
        })
    }

    getPresCategoryVOListType(row, type) {
        const info = row.presCategoryVOList?.find((el) => el.preCategoryId == type);
        return info?.presCategoryDeviceAndProductVOList || [];
    }

    copyUrl() {
        this.copy(`${this.curReportUrl}?symptomId=${this.categoryInfo.id}`);
    }

    // 获取下拉框options 
    pageLoading = false;
    prescriptionLoadPres(symptomId) {
        this.pageLoading = true;
        prescriptionLoadPres(symptomId, this.merchantId).then((res) => {
            this.tableData = res.data || [];
        }).finally(() => {
            this.pageLoading = false;
        })
    }

    getTlCategoryListLoading = false;
    getTlCategoryList(parentId: number) {
        this.getTlCategoryListLoading = true;
        this.tllxOptions = [];
        getCategoryList(parentId, this.merchantId).then((res) => {
            this.getTlCategoryListLoading = false;
            this.tllxOptions = res.data || [];
        })
    }

    // 设备类型改变，需要将产品的信息清空，重新填写
    tlCategoryChange($event, value) {
        if (!value || !$event) return;
        this.formObj[`formData${value}`].categoryProductId = "";
        this.formObj[`formData${value}`].categoryProductInfo = {};
        this.formObj[`formData${value}`].categoryDeviceId = $event.id;
        this.formObj[`formData${value}`].categoryDeviceName = $event.name;
    }

    // 产品选择
    cpCategoryChange($event, value){
        if (!value || !$event) return;
        this.$set(this.formObj[`formData${value}`], "categoryProductId", $event.id)
        this.$set(this.formObj[`formData${value}`], "cpCategoryName", $event.name)
        this.formObj = cloneDeep(this.formObj);
    }

    getCpCategoryListLoading = false;
    getCpCategoryList(parentId: number) {
        this.getTlCategoryListLoading = true;
        this.cpmcOptions = [];
        getCategoryList(this.formObj[`formData${parentId}`].categoryDeviceId, this.merchantId).then((res) => {
            this.getTlCategoryListLoading = false;
            this.cpmcOptions = res.data || [];
        })
    }

    // 左侧表单填写完成之后，添加到右侧
    addPrescript(row) {
        this.dialogVisible = true;
        if (!row) return;
        this.editRow = cloneDeep(row);
        // 这里要把tag 全部回显
        this.plan = row.plan;
        this.remark = row.remark;
        // 编辑的时候，需要将数据格式化处理 到前端使用的字段
        row?.presCategoryVOList?.forEach((C) => {
            const { preCategoryId, presCategoryDeviceAndProductVOList = [] } = C;
            if (!presCategoryDeviceAndProductVOList) return;
            const tagList = this.tagListObj[`tagList${preCategoryId}`];
            presCategoryDeviceAndProductVOList.forEach((P) => {
                const { preCategoryDeviceId, preCategoryDeviceName, preCategoryProductId, preCategoryProductName, productNum, productUnit } = P;
                tagList.push({
                    categoryId: preCategoryId,
                    categoryDeviceId: preCategoryDeviceId,
                    categoryProductId: preCategoryProductId,
                    productNum,
                    productUnit, 
                    cpCategoryName: preCategoryProductName,
                    categoryDeviceName: preCategoryDeviceName,
                })
            })
        })

    }
    addXmPrescript(type) {
        const info = cloneDeep(this.formObj[`formData${type}`]);
        this.tagListObj[`tagList${type}`].push({ ...info, categoryId: type })
        this.tagListObj = cloneDeep(this.tagListObj);
        this.formObj[`formData${type}`] = {};
    }
    rmTag(type, index) {
        this.tagListObj[`tagList${type}`]?.splice(index, 1)
        this.tagListObj = cloneDeep(this.tagListObj);
    }
    editProductRow() {
        this.dialogVisible = true;
    }
    prescriptionDisable(item) {
        this.$confirm(`是否删除处方【${item.name}】`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            prescriptionDisable(item.id).then((res) => {
                this.$message.success("操作成功")
                this.prescriptionLoadPres(this.categoryInfo.id)
            })
        })
    }

    dialogSecondConfirm() {
        this.$confirm('是否关闭弹窗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.dialogClose();
        })
    }

    dialogClose() {
        this.dialogVisible = false;
        this.validateForm?.resetFields();
        Object.entries(this.formObj).forEach(([key, value]) => {
            this.formObj[key] = {}
        })
        Object.entries(this.tagListObj).forEach(([key, value]) => {
            this.tagListObj[key] = []
        })
        this.plan = "";
        this.remark = "";
    }
    dialogConfirm() {

        const params = {
            presProducts: this.firstCategoryList?.reduce((acc, cur) => {
                return ([...acc, ...this.tagListObj[`tagList${cur.id}`]])
            }, []),
            prescription: {
                presSymptomId: this.categoryInfo.id,
                plan: this.plan,
                remark: this.remark,
                name: this.categoryInfo.name,
                // @ts-ignore
                id: this.editRow?.id,
                merchantId: this.merchantId,
                ownerType: this.merchantId ? 1 : 0, 
            }
        }
        if (params.presProducts.length == 0 && !this.plan && !this.remark) {
            this.$message.warning("请至少填写一项");
            return;
        }

        prescriptionSaveOrUpdate(params).then((res) => {
            this.btnLoading = false;
            this.dialogClose();
            this.$message.success("操作成功");
            // 触发一下列表查询
            this.prescriptionLoadPres(this.categoryInfo.id)
        })
    }
}
