import { Component, Vue, Prop } from 'vue-property-decorator'
import clipboard from "clipboard"; 
@Component
export default class CopyString extends Vue {

    copy(str = "", className = ".cobyAddInfo") {
        const clipboardInfo = new clipboard(className, {
            text: (trigger) => {
                return str;
            },
        });
        clipboardInfo.on("success", () => {
            this.$message.success("复制成功");
            clipboardInfo.destroy();
        });
        clipboardInfo.on("error", (error) => {  
            this.$message.error("复制失败，请手动复制");
            clipboardInfo.destroy();
        });
    }
}