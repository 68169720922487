
import { Watch, Emit, Prop, Component, Vue, InjectReactive } from "vue-property-decorator";
import PrescriptionOne from './prescriptionOne.vue';
import { prescriptionSymptom } from "@/api/prescriptionDb";

@Component({
    components: {
        PrescriptionOne
    }
})
export default class PulseTaking extends Vue {
    @Prop() readonly currentTab: number | string
    @InjectReactive() pageCurrentTab!: string|number;
    @InjectReactive() merchantId!: string|number;

    activeName = "1"
    list = [];
    total = 0;

    pageLoading = false;

    collapseChange(id) {
        if (!id) return;
        this.$nextTick(() => {
            const ref = this.$refs.prescriptionOneRef[0];
            ref.prescriptionLoadPres(id, this.merchantId);
        })
    }

    @Emit("syncTotal")
    syncTotal() {
        return this.total;
    }

    @Watch("currentTab", { immediate: true })
    prescriptionSymptom(value, queryParams = {}) {
        if (value != 1) return; 
        const params = {
            libCode: "10003",
            symptomName: "",
            pageNum: 1,
            pageSize: 20,
            merchantId: this.merchantId,
        }
        if (queryParams) {
            Object.assign(params, queryParams)
        }
        this.pageLoading = true;
        prescriptionSymptom(params).then((res) => {
            this.list = res.data.list || [];
            this.total = res.data.total;
            this.syncTotal();
        }).finally(() => {
            this.pageLoading = false;
        })
    }

}
